
import React from 'react'
import Obfuscate from 'react-obfuscate'
import styled from "styled-components";

import IconLocation from '../assets/icons/SVG/location.svg'
import IconPhone from '../assets/icons/SVG/phone.svg'
import IconWhatsapp from '../assets/icons/SVG/whatsapp.svg'
import IconEmail from '../assets/icons/SVG/email.svg'

const StyledContainer = styled.div`
  width: 100%;
  padding: 15px 0 30px 0;
  color: #fff;
  text-align: center;
  fill: #fff;

  background: #1b2831;
  background: -moz-linear-gradient(left, #1b2831 0%, #0e5474 50%, #1b2831 100%);
  background: -webkit-linear-gradient(left, #1b2831 0%,#0e5474 50%,#1b2831 100%);  
`;

const StyledWrapper = styled.div`
  @media (max-width: 960px) {
    padding-left: 100px;
    padding-right: 100px
  }

  @media (max-width: 767px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const StyledLink = styled.div`
  display: inline-block;

  > a {
    text-decoration: none;
    color: #fff;
  }
`;

const StyledIcon = styled.span`
  position: relative;
  top: 5px;
  margin-left: 15px;
`;

const StyledContact = styled.span`
  white-space: nowrap
`;

const Footer = () => (
  <StyledContainer>
    <StyledWrapper>
      <p><StyledLink><StyledIcon><IconLocation /></StyledIcon> Medicadiz sede la Samaria Cra. 12 sur # 93-21 / 134 (vía Aeropuerto) Consultorios: 911 - 912 Ibagué, Colombia</StyledLink></p>
      <StyledIcon><IconPhone /></StyledIcon> 
      <StyledLink>
        <Obfuscate
          tel="(578) 515 51 98"
        />
      </StyledLink>
      <span> - </span>
      <StyledLink>
        <Obfuscate
          tel="321 209 0828"
        />
      </StyledLink>
      <span> - </span>
      <StyledLink>
        <Obfuscate
          tel="313 611 8921"
        />
      </StyledLink>
      <span> - </span>
      <StyledLink>
        <Obfuscate
          tel="320 416 1441"
        />
      </StyledLink>
      <StyledIcon><IconWhatsapp /></StyledIcon> 
      <StyledLink>
        <Obfuscate
          tel="321 209 0828"
        />
      </StyledLink>
      <StyledContact>
        <StyledIcon><IconEmail /></StyledIcon>
        <StyledLink>
          <Obfuscate
            email="cardiodx@gmail.com"
            headers={{
              subject: '',
            }}
          />
        </StyledLink>
      </StyledContact>
    </StyledWrapper>
  </StyledContainer>
)

export default Footer
import React from 'react'
import { withPrefix } from 'gatsby-link'
import styled from "styled-components";
import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

import SEO from "../components/seo"
import Footer from '../components/footer'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  body{
    font-family: 'Lato', sans-serif;
  }
`;

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 90vw;
  height: 80vh;
  text-align: center;
  margin: 0 auto;
`;

const StyledLogo = styled.img`
  width: 100%;
`;

const StyledTitle = styled.h1`
  font-size: 1.1em;
  text-transform: uppercase;
`;

const StyledLocation = styled.h2`
  font-size: 1em;
  text-transform: uppercase;
`;

const StyledDescription = styled.div`
  color: #0286ca;
  margin-top: 60px;

  > p {
    margin: 7.5px 0;
  }
`;

const StyledItem = styled.span`
  text-transform: uppercase;
  margin-right: 7.5px;
    
  &::before {
    content: '• ';
  }
`;

const StyledFooter = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;

  @media (max-width: 767px) {
    position: relative;
  }
`;

const IndexPage = () => (
  <>
    <SEO title="..." />
    <GlobalStyle />
    <StyledCard>
      <div>
        <StyledLogo
          src={withPrefix('/images/cardiodiagnostico-ibague.png')} alt="Cardiodiagnóstico Ibagué"
        />
        <StyledTitle>
          Dr. Carlos Eduardo Cepeda Garzon
        </StyledTitle>
        <StyledLocation>
          Médico Cardiólogo - T.M. 5035 Tolima
        </StyledLocation>
        <StyledDescription>
          <p><StyledItem>Ecocardiografía Transtorácica - Transesofágica - Eco-estres</StyledItem></p>
          <p><StyledItem>Prueba De Esfuerzo Holter </StyledItem><StyledItem>Ecg 24 Horas</StyledItem></p>
          <p><StyledItem>Monitoreo Ambulatorio De Presión Arterial-mapa</StyledItem></p>
        </StyledDescription>
      </div>
    </StyledCard>
    <StyledFooter>
      <Footer />
    </StyledFooter>
  </>
)

export default IndexPage
